$(document).ready(function() {

	$('#sidebarCollapse').on('click', function() {
		$('#sidebar').toggleClass('active');
		$('#main').toggleClass('sidebar');
		$('#navbar').toggleClass('sidebaropen');
		$('#sidebarCollapse').toggleClass('closed');
	});

	$('#mobileCollapse').on('click', function() {
		$('#mobileNav').toggleClass('visible');
	});
});
